






import Vue from 'vue';
import Invoice from '../../components/Billings/Invoice.vue';
export default Vue.extend({
    name: 'BillingsPage',
    components: {
        Invoice
    },
    metaInfo(){
      return  {
      title: "Billings - Our Nation Express",
     
    };
    }
})
