






import Vue from 'vue'
import BillingsPage from '../billings/Billings.vue'
export default Vue.extend({
    name: 'BillingsIndex',
    components: {
        BillingsPage
    },
})
